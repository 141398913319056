/* eslint-disable */
/* tslint:disable */
import * as Types from '../generated';

import { gql } from '@apollo/client';
import { SmartAddressFragmentDoc, FileFragmentDoc, CustomAddressFragmentDoc, MoneyFragmentDoc } from './CoreFragments.generated';
export const LeadFragmentDoc = gql`
    fragment Lead on Lead {
  id
  type
  uuid
  name
  surname
  email
  phone
  status
  supplyAddress {
    ...SmartAddress
  }
  contactAddress {
    ...SmartAddress
  }
  attachments {
    id
    file {
      ...File
    }
  }
  customerNote
  lastStatusChange
  completedAt
  sendEmailImmediately
}
    ${SmartAddressFragmentDoc}
${FileFragmentDoc}`;