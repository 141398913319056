/* eslint-disable */
/* tslint:disable */
import * as Types from '../generated';

import { gql } from '@apollo/client';
export const RatingCategoryFragmentDoc = gql`
    fragment RatingCategory on RatingCategory {
  id
  label
  value
}
    `;
export const NewRatingFragmentDoc = gql`
    fragment NewRating on NewRating {
  id
  total
  categories {
    ...RatingCategory
  }
}
    ${RatingCategoryFragmentDoc}`;